

export const loginUsernameValidator = (item, t) => {
    const rules = {
        required: {
            value: true,
            message: t('this_field_is_required')
        },
    };

    if (item.type === 'text') {
        rules.minLength = {
            value: item.validation.max,
            message: `${t('min_length_is')} ${item.validation.max}`,
        };
        rules.maxLength = {
            value: item.validation.min,
            message: `${t('max_length_is')} ${item.validation.min} `,
        };
        rules.pattern = {
            value: /^[A-Za-z0-9]+$/,
            message: t("invalid_format"),
        }
    } else if (item.type === 'password') {
        rules.minLength = {
            value: item.validation.minLength,
            message: `${t('min_length_is')} ${item.validation.minLength}`,
        };
    }
    return rules;
}

export const loginPasswordValidator = (item, t) => {
    const rules = {
        required: {
            value: true,
            message: t('this_field_is_required')
        },
    };

    if (item.validation.minLength) {
        rules.minLength = {
            value: item.validation.minLength,
            message: `${t('min_length_is')} ${item.validation.minLength}`
        };
    }

    const regexParts = [];
    if (item.validation.specialChar) {
        regexParts.push("(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"|,.<>?])"); // At least one special character
    }
    if (item.validation.number) {
        regexParts.push("(?=.*[0-9])"); // At least one number
    }
    if (item.validation.mixedCase) {
        regexParts.push("(?=.*[A-Z])"); // At least one capital letter
    }

    if (regexParts.length > 0) {
        rules.pattern = {
            value: new RegExp(`^${regexParts.join("")}.*$`),
            message: t('Almost there! Check the password requirements again.'),
        };
    }
    return rules;
};

export const passwordRules = (item) => {
    const rules = []
    if (item.minLength) {
        rules.push('minLength')
    }
    if (item.spChar) {
        rules.push('specialChar');
    }
    if (item.number) {
        rules.push('number');
    }
    if (item.mixedCase) {
        rules.push('capital');
    }
    return rules
}

export const validateAge = (value, t) => {
    const currentDate = new Date();
    const selectedDate = new Date(value);
    const minAge = 18; // Change this to your desired minimum age
    if (currentDate.getFullYear() - selectedDate.getFullYear() < minAge) {
        return t("you_must_be_at_least_18_years_old.");
    }

    return true;
};

