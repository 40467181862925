import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BoardViewTiles from "../../components/board-view/BoardViewTiles";
import BoardViewTable from "../../components/board-view/BoardViewTable";
import { ApiHook } from "../../hooks/apiHook";

const BoardView = () => {
    const { t } = useTranslation();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [boardId, setBoardId] = useState(null);
    const boardTiles = ApiHook.CallGetBoardTiles();
    const boards = ApiHook.CallGetBoards(boardId, currentPage, itemsPerPage);

    useEffect(() => {
        if (boardTiles.data) {
            setBoardId(boardTiles.data.data.tiles[0].id);
        }
    }, [boardTiles.data])

    return (
        <>
            <div className="page_head_top">{t("boardView")}</div>
            <BoardViewTiles boardTiles={boardTiles} setBoardId={setBoardId} />
            <BoardViewTable
                boards={boards}
                boardId={boardId}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
};

export default BoardView;