import React, { useState } from "react";
import { useParams } from "react-router";
import { ApiHook } from "../../hooks/apiHook";
import BoardTreeContent from "../../components/board-view/BoardTreeContent";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";

const BoardTree = () => {
    const params = useParams();
    // const [selectedUserId, setSelectedUserId] = useState("");
    // const [searchUsername, setSearchUsername] = useState("");
    // const [doubleClickedUser, setDoubleClickedUser] = useState("");

    // api
    const boardTree = ApiHook.CallViewBoard(params.boardId, params.autoBoardId);
    return (
        <>
            <TreeViewNavbar
                menu={"boardView"}
                // searchUsername={searchUsername}
                // setSearchUsername={setSearchUsername}
                // setSelectedUserId={setSelectedUserId}
            />
            <BoardTreeContent
                // selectedUserId={selectedUserId}
                // setSelectedUserId={setSelectedUserId}
                // doubleClickedUser={doubleClickedUser}
                // setDoubleClickedUser={setDoubleClickedUser}
                // searchUsername={searchUsername}
                // setSearchUsername={setSearchUsername}
                isLoading={boardTree.status === "loading" || boardTree.fetchStatus === "fetching"}
            />
        </>
    )
};

export default BoardTree;